import React, { useEffect, useState } from 'react';
import { Button, Flex, Layout, Menu, Modal, Progress, Spin, Steps, message, notification } from 'antd';
import classNames from 'classnames';
import $ from 'jquery';
import { isMobile } from 'react-device-detect';
import { BsFillChatRightTextFill } from 'react-icons/bs';
import { FaRegEdit, FaUser } from 'react-icons/fa';
import { GoCheckCircleFill } from 'react-icons/go';
import { MdOutlineTipsAndUpdates } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSound from 'use-sound';

import TypingMessage from '../../../components/TypingMessage';
import LimitModal from '../../pages/Home/Partials/LimitModal';
import WelcomebackModal from '../../pages/Home/Partials/WelcomebackModal';
import { ExclamationCircleFilled, MenuOutlined, SignalFilled, YoutubeFilled } from '@ant-design/icons';
import PurposeModal from './PurposeModal';
import SafeModal from './SafeModal';
import StepModal from './StepModal';
import UserMenu from './UserMenu';
import boopSfx from '../../../assets/sounds/effect1.mp3';
import { useSocket } from '../../../context/socket';
import { getStorage, setStorage } from '../../../helpers';
import { setSiderCollapsed } from '../../../redux/app/appSlice';
import { getUser } from '../../../redux/auth/authSlice';
import { getMessages, pushMessages, setGenerating, setHasMore, setMessages, setPage } from '../../../redux/message/messageSlice';
import { addThread, getThreads, setOpenPayModal, setSelectedThread, setShowExpiredModal } from '../../../redux/thread/threadSlice';

import { newThread, sendMessage, updateThread } from '../../../services/threadAPI';
import usePrevious from '../../../Hooks/usePrevious';

import "./SideBar.scss";

const { Sider } = Layout;

function SideBar() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const socket = useSocket();

  const { id } = useParams();

  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const user = useSelector((state) => state.auth.user);
  const isPayed = useSelector((state) => state.auth.user.activeSubscription && (state.auth.user.activeSubscription.status == 'active' || state.auth.user.activeSubscription.status == 'trialing'));
  const selectedThread = useSelector((state) => state.thread.selectedThread);
  const threads = useSelector((state) => state.thread.threads);
  const thread = useSelector((state) => state.thread.threads.find(_thread => _thread._id === selectedThread));
  const showExpiredModal = useSelector((state) => state.thread.showExpiredModal);
  const messages = useSelector((state) => state.message.messages);
  const page = useSelector((state) => state.message.page);
  const limit = useSelector((state) => state.message.limit);
  const hasMore = useSelector((state) => state.message.hasMore);
  const loading = useSelector((state) => state.message.loading);
  const totalMessages = useSelector((state) => state.message.total);
  const step = useSelector((state) => parseInt(state.message.total / 5));
  const [current, setCurrent] = useState("");
  const [openTour, setOpenTour] = useState(false);
  const [showAnalyzeModal, setShowAnalyzeModal] = useState(false);
  const [showWelcomebackModal, setShowWelcomebackModal] = useState(false);
  const [showSafeModal, setShowSafeModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("positive");
  const [analysisData, setAnalysisData] = useState(null);
  const [items, setItems] = useState([]);
  const [showPurposeModal, setShowPurposeModal] = useState(false);
  const [titling, setTitling] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [tab, setTab] = useState('chats');
  const [openKey, setOpenKey] = useState('advice');

  const prevTitling = usePrevious(titling);

  const [effectSound] = useSound(boopSfx);

  const toolsItems = [
    {
      key: 'advice',
      label: <span className="font-semibold text-[16px]">Get Advice</span>,
      icon: <MdOutlineTipsAndUpdates />,
      children: [
        {
          key: 'actionable',
          label: <div className='flex justify-between items-center'><span>Actionable</span> {current == 'actionable' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'empathetic',
          label: <div className='flex justify-between items-center'><span>Empathetic</span> {current == 'empathetic' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'perspectiveShifting',
          label: <div className='flex justify-between items-center'><span>Perspective-Shifting</span> {current == 'perspectiveShifting' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'thoughtProvoking',
          label: <div className='flex justify-between items-center'><span>Thought Provoking</span> {current == 'thoughtProvoking' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'philosophical',
          label: <div className='flex justify-between items-center'><span>Philosophical</span> {current == 'philosophical' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'selfReflection',
          label: <div className='flex justify-between items-center'><span>Self-Reflection</span> {current == 'selfReflection' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'spiritual',
          label: <div className='flex justify-between items-center'><span>Spiritual</span> {current == 'spiritual' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
      ],
    },
    {
      key: 'analysis',
      label: <span className="font-semibold text-[16px]">Analysis</span>,
      icon: <SignalFilled />,
      children: [
        {
          key: 'worldview',
          label: <div className='flex justify-between items-center'><span>Worldview Analysis</span> {current == 'worldview' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'decisionMaking',
          label: <div className='flex justify-between items-center'><span>Decision Making</span> {current == 'decisionMaking' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'habits',
          label: <div className='flex justify-between items-center'><span>Habits Analysis</span> {current == 'habits' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'speculative',
          label: <div className='flex justify-between items-center'><span>Speculative Analysis</span> {current == 'speculative' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'cognitiveDistortion',
          label: <div className='flex justify-between items-center'><span>Cognitive Distortion Analysis</span> {current == 'cognitiveDistortion' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'relationship',
          label: <div className='flex justify-between items-center'><span>Relationship Analysis</span> {current == 'relationship' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'intent',
          label: <div className='flex justify-between items-center'><span>Intent Analysis</span> {current == 'intent' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'valuesBelief',
          label: <div className='flex justify-between items-center'><span>Values and Belief Analysis</span> {current == 'valuesBelief' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'stressCoping',
          label: <div className='flex justify-between items-center'><span>Stress and Coping Analysis</span> {current == 'stressCoping' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
      ],
    },
    {
      key: 'personality',
      label: <span className="font-semibold text-[16px]">Personality</span>,
      icon: <FaUser />,
      children: [
        {
          key: 'personalityArchetype',
          label: <div className='flex justify-between items-center'><span>Personality Archetype Analysis</span> {current == 'personalityArchetype' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'psychodynamic',
          label: <div className='flex justify-between items-center'><span>Psychodynamic Analysis</span> {current == 'psychodynamic' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'attachmentStyle',
          label: <div className='flex justify-between items-center'><span>Attachment Style Analysis</span> {current == 'attachmentStyle' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'moralEthicalReasoning',
          label: <div className='flex justify-between items-center'><span>EmpatheticMoral and Ethical Reasoninspang Analysis</span> {current == 'moralEthicalReasoning' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'valuesMotivational',
          label: <div className='flex justify-between items-center'><span>Values and Motivational Analysis</span> {current == 'valuesMotivational' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'emotionalIntelligence',
          label: <div className='flex justify-between items-center'><span>EmpatheticEmotional Intelligence (EQ) Analysis</span> {current == 'emotionalIntelligence' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'psychologicalNeeds',
          label: <div className='flex justify-between items-center'><span>EmpatheticShadow Personality Analysis Traits</span> {current == 'psychologicalNeeds' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'shadowPersonality',
          label: <div className='flex justify-between items-center'><span>Psychological Needs Analysis</span> {current == 'shadowPersonality' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'socialIdentity',
          label: <div className='flex justify-between items-center'><span>Social Identity Analysis</span> {current == 'socialIdentity' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'situationalPersonality',
          label: <div className='flex justify-between items-center'><span>Situational Personality Analysis</span> {current == 'situationalPersonality' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
      ],
    },
    {
      key: 'content',
      label: <span className="font-semibold text-[16px]">Related Content</span>,
      icon: <YoutubeFilled />,
      children: [
        {
          key: 'blogPosts',
          label: <div className='flex justify-between items-center'><span>Blog Posts</span> {current == 'blogPosts' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'videos',
          label: <div className='flex justify-between items-center'><span>Videos</span> {current == 'videos' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'podcasts',
          label: <div className='flex justify-between items-center'><span>Podcasts</span> {current == 'podcasts' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
        {
          key: 'researchArticles',
          label: <div className='flex justify-between items-center'><span>Research Articles</span> {current == 'researchArticles' && <Spin size='small' className="[&_.ant-spin-dot-item]:bg-white" />}</div>,
        },
      ],
    },
  ];

  const onToolsClick = (e) => {
    console.log('click ', e.key);
    if (current) {
      return notification.info({
        description: 'Please wait for the current analysis to finish.',
      });
    }
    setCurrent(e.key);
    // setOpenModal(`/data/${e.key}`);
    // navigate(`/data/${e.key}`);
    socket.emit('analysisMessage', { dataKey: e.key, threadId: selectedThread });
    if (isMobile) {
      dispatch(setSiderCollapsed(true));
    }
  };

  const setOpenModal = (to) => {
    if (user.activeSubscription && (user.activeSubscription.status != 'active' && user.activeSubscription.status != 'trialing')) {
      Modal.confirm({
        title: 'Your last invoice payment was failed!',
        icon: <ExclamationCircleFilled />,
        content: 'Will you try to pay the invoice again?',
        okText: "Yes",
        cancelText: "No",
        onOk() {
          window.open(user.activeSubscription.hosted_invoice, '_blank');
          return new Promise((resolve) => {
            setTimeout(resolve, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          console.log('Cancel');
          dispatch(setOpenPayModal(to));
        },
      });
    } else if (user.activeSubscription && (user.activeSubscription.status == 'active' || user.activeSubscription.status === 'trialing')) {
      navigate(to);
    } else {
      dispatch(setOpenPayModal(to));
    }
  };

  const handleNewChat = () => {
    if (!isPayed && user.credits == 0) {
      return message.error('Please upgrade to PRO to start a new chat.');
    }
    newThread().then(res => {
      dispatch(addThread(res.data.thread));
      setTimeout(() => {
        dispatch(setPage(1));
        dispatch(setHasMore(true));
        dispatch(setMessages([]));
        navigate(location.pathname.replace(selectedThread, res.data.thread._id));
      }, 50);
    }).catch(err => console.log(err)).finally(() => {
      setShowWelcomebackModal(false);
    });
  };

  useEffect(() => {
    if (!!getStorage('registered')) {
      setShowSafeModal(!!getStorage('registered'));
    } else {
      dispatch(getThreads());
    }
  }, []);

  useEffect(() => {
    let sessionId = searchParams.get('sessionId');
    if (sessionId) {
      window.gtag('event', 'conversion', { 'send_to': 'AW-16565329006/84qjCODxss0ZEO64-9o9' });
    }
  }, []);

  useEffect(() => {
    if (id && id !== selectedThread) {
      dispatch(setSelectedThread(id));
    } else if (!id && selectedThread) {
      if (location.pathname.includes('playground')) {
        navigate(selectedThread);
      }
    }
  }, [id, selectedThread]);

  useEffect(() => {
    if (socket && current) {
      socket.on('_analysisMessage', (data) => {
        console.log('_analysisMessage', data);
        setCurrent("");
      });
    }
    if (socket) {
      socket.on('analysisMessage', (data) => {
        dispatch(setGenerating(true));
        dispatch(pushMessages({
          data: [
            {
              role: 'assistant',
              content: "",
            }
          ],
          isPush: true,
        }));
      });
    }
    return () => {
      if (socket) {
        socket.off('_analysisMessage');
        socket.off('analysisMessage');
      }
    };
  }, [socket, current]);

  useEffect(() => {
    if (threads.length > 0) {
      setItems(threads.map((thread) => {
        if (thread.subTitles?.length > 0) {
          return {
            key: thread._id,
            label: thread.title ? <p className='m-0 text-[16px] font-bold max-w-full truncate' title={thread.title}>{(animating && thread._id == selectedThread) ? <TypingMessage message={thread.title} only /> : thread.title}</p> : <Flex justify='between' align='center'><span className='text-[16px] font-bold flex-1 truncate'>New chat</span> <Spin size='small' spinning={thread._id == selectedThread && titling} /></Flex>,
            icon: <BsFillChatRightTextFill />,
            children: thread.subTitles.map((subTitle, index) => {
              return {
                key: thread._id + index,
                icon: <span className='!text-black font-bold bg-white rounded-full w-[18px] h-[18px] flex items-center justify-center'>{index + 1}</span>,
                label: <p className='m-0 text-[14px] font-bold max-w-full truncate'>{subTitle.title || "New chat"}</p>,
                onClick: async () => {
                  console.log('----', subTitle.desc);
                  dispatch(setMessages([
                    {
                      role: 'assistant',
                      content: subTitle.desc,
                      animate: true,
                    },
                    ...messages,
                  ]));
                  await sendMessage(thread._id, { prompt: subTitle.desc });
                }
              };
            }),
          };
        }
        return {
          key: thread._id,
          label: <p className='m-0 text-[16px] font-bold max-w-full truncate'>{thread.title || "New chat"}</p>,
          icon: <BsFillChatRightTextFill />,
          onClick: () => {
            if (selectedThread == thread._id) return;
            dispatch(setPage(1));
            dispatch(setHasMore(true));
            dispatch(setMessages([]));
            dispatch(setSelectedThread(thread._id));
            navigate(location.pathname.replace(selectedThread, thread._id));
          }
          // children: [
          //   {
          //     key: thread._id + '0',
          //     className: 'stepper-view-menu',
          //     label: <>
          //       <Steps
          //         direction="vertical"
          //         size="small"
          //         current={step}
          //         className={classNames('h-full min-h-72 analysis-steps', `step-${step + 1} percent-${(totalMessages % 5) * 20}`)}
          //         items={[
          //           {
          //             title: <span className="font-bold">Listening ({step == 0 ? parseInt(totalMessages % 5) : 5}/5)</span>,
          //           },
          //           {
          //             title: <span className="font-bold">Analyzing ({step == 1 ? parseInt(totalMessages % 5) : (step > 1 ? 5 : 0)}/5)</span>,
          //           },
          //           {
          //             title: <span className="font-bold">Roadmap</span>,
          //           },
          //         ]}
          //       />
          //     </>
          //   },
          // ]
        };
      }));
      if (!selectedThread) {
        dispatch(setSelectedThread(threads[0]._id));
      }
    }
  }, [threads, selectedThread, totalMessages, step, messages, titling, animating]);

  useEffect(() => {
    if (thread && !thread.goal) {
      setShowPurposeModal(true);
    } else if (thread && thread.goal) {
      setShowPurposeModal(false);
    }
  }, [thread]);

  useEffect(() => {
    if (socket) {
      socket.on('answer', (data) => {
        // console.log(data);
        if (data.end) {
          dispatch(setGenerating(false));
        } else {
          if (selectedThread == data.threadId) {
            dispatch(pushMessages({
              data: [{
                role: 'assistant',
                content: data.content,
              }],
              isPush: false,
            }));
          }
        }
      });

      socket.on('limitReached', () => {
        dispatch(getUser());
        dispatch(getThreads());
        dispatch(setShowExpiredModal(true));
        /* if (!isPayed) {
        } */
      });
    }
    return () => {
      if (socket) {
        socket.off('answer');
        socket.off('limitReached');
      }
    }
  }, [socket, selectedThread]);

  useEffect(() => {
    if (socket) {
      socket.on("analysisData", (data) => {
        effectSound();
        setAnalysisData(data);
        setShowAnalyzeModal(true);
      });
      socket.on("analyzed_thread", (data) => {
        // console.log("analyzed_thread", data);
        effectSound();
        dispatch(getThreads());
      });
      socket.on("SUBSCRIPTION_EVENT", (data) => {
        console.log('SUBSCRIPTION_EVENT', data);
        dispatch(getUser());
        dispatch(getThreads());
      });
    }

    return () => {
      if (socket) {
        socket.off("SUBSCRIPTION_EVENT");
        socket.off("analysisData");
        socket.off("analyzed_thread");
      }
    }
  }, [socket, effectSound]);

  useEffect(() => {
    // console.log(thread, isPayed);
    if (thread && thread.back == 1 && !isPayed) {
      setShowWelcomebackModal(true);
    } else if (thread && thread.status == 1 && !isPayed) {
      dispatch(setShowExpiredModal(true));
    } else if (thread && isPayed) {
      dispatch(setShowExpiredModal(false));
      setShowWelcomebackModal(false);
    } else if (thread && thread.status !== 1) {
      setShowWelcomebackModal(false);
      dispatch(setShowExpiredModal(false));
    }
  }, [thread, isPayed]);

  useEffect(() => {
    if (!loading && selectedThread && hasMore && thread && thread.goal) {
      console.log('loading...')
      dispatch(getMessages({ threadId: selectedThread, query: { page, limit } }));
    }
  }, [selectedThread, page, thread]);

  useEffect(() => {
    if (prevTitling == true && titling == false) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
      }, 1000);
    }
  }, [prevTitling, titling]);

  return (
    <Sider
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarColor: 'unset',
        zIndex: 999,
      }}
      breakpoint='md'
      onBreakpoint={(broken) => {
        if (broken) {
          dispatch(setSiderCollapsed(true));
        } else {
          dispatch(setSiderCollapsed(false));
        }
      }}
      className={classNames(!isPayed && "", 'sidebar !bg-transparent')}
      collapsedWidth={0}
      width={isMobile ? "100%" : 288}
      collapsed={siderCollapsed}
      collapsible
      trigger={null}
      onClick={(e) => {
        if ($(e.target).hasClass('ant-layout-sider-children')) {
          dispatch(setSiderCollapsed(true));
        }
      }}
    >
      <div className={classNames("inline-flex flex-col h-full bg-gray-100 w-72")}>
        <div className={classNames('h-16 flex items-center justify-between sticky top-0 z-[9999] bg-gray-100 shadow-sm', isMobile ? 'justify-between' : '')}>
          <Button icon={<MenuOutlined />} type='text' className="ml-2" onClick={() => {
            dispatch(setSiderCollapsed(!siderCollapsed));
          }} />
          {/* {isMobile && <div className='flex items-center gap-4 mr-2'>
            <Link to="/playground" className="text-gray-600" onClick={(e) => {
              e.preventDefault();
              dispatch(setSiderCollapsed(!siderCollapsed));
              navigate('/playground');
            }}>
              Text Chat
            </Link>
            <Link to="/voice-playground" className="text-gray-600" onClick={(e) => {
              e.preventDefault();
              dispatch(setSiderCollapsed(!siderCollapsed));
              navigate('/voice-playground');
            }}>
              Voice Chat
            </Link>
            <UserMenu />
          </div>} */}
        </div>
        {isPayed && <div className='flex items-center justify-center py-1 border-b border-solid border-gray-200 border-0'>
          <div className="flex items-center justify-center gap-2 py-1.5 px-4 rounded-3xl bg-zinc-200 mt-2 mb-1">
            <Button className={classNames(tab == 'chats' ? '!bg-white !shadow !text-blue-600' : '!text-gray-500 bg-transparent border-0 shadow-none hover:!bg-white hover:!shadow hover:!text-blue-600')} onClick={() => setTab('chats')} shape='round'>Chats</Button>
            <Button className={classNames(tab == 'tools' ? '!bg-white !shadow !text-blue-600' : '!text-gray-500 bg-transparent border-0 shadow-none hover:!bg-white hover:!shadow hover:!text-blue-600')} onClick={() => {
              if (isPayed) {
                return setTab('tools');
              }
              message.error('Please upgrade to PRO to use tools.');
            }} shape='round'>Tools</Button>
          </div>
        </div>}
        <div className="flex-1 overflow-y-auto">
          {/* {(!isPayed && messages.length > 0) && <>
            <div className="gap-4 p-6 text-center min-h-96">
              <Steps
                direction="vertical"
                size="small"
                current={step}
                className={classNames('h-full min-h-96 analysis-steps', `step-${step + 1} percent-${(totalMessages % 10) * 10}`)}
                items={[
                  {
                    title: <span className="font-bold">Listening ({step == 0 ? parseInt(totalMessages % 10) : 10}/10)</span>,
                  },
                  {
                    title: <span className="font-bold">Analyzing ({step == 1 ? parseInt(totalMessages % 10) : (step > 1 ? 10 : 0)}/10)</span>,
                  },
                  {
                    title: <span className="font-bold">Roadmap </span>,
                  },
                ]}
              />
            </div>
          </>} */}
          {(!siderCollapsed && tab == 'chats') && <>
            <Menu
              // onClick={onClick}
              className='w-full sidebar-menu'
              openKeys={[selectedThread]}
              selectedKeys={[selectedThread]}
              mode="inline"
              items={items}
              onOpenChange={(openKeys) => {
                if (openKeys[openKeys.length - 1]) {
                  dispatch(setPage(1));
                  dispatch(setHasMore(true));
                  dispatch(setMessages([]));
                  dispatch(setSelectedThread(openKeys[openKeys.length - 1]));
                  navigate(location.pathname.replace(openKeys[0], openKeys[1]));
                }
              }}
            /*  onSelect={({ key }) => {
               console.log(key);
               // dispatch(setPage(1));
               // dispatch(setHasMore(true));
               // dispatch(setMessages([]));
               // dispatch(setSelectedThread(key));
               // navigate(location.pathname.replace(selectedThread, key));
             }} */
            />
            {/* isPayed && */ <div className='h-16 p-4 text-center'>
              <Button className='inline-flex items-center' icon={<FaRegEdit />} type='primary' onClick={handleNewChat}>New Chat</Button>
            </div>}
          </>
          }
          {(!siderCollapsed && tab == 'tools') && <>
            <Menu
              onClick={onToolsClick}
              className='w-full sidebar-menu'
              openKeys={[openKey]}
              selectedKeys={[current]}
              mode="inline"
              items={toolsItems}
              onOpenChange={(openKeys) => {
                if (openKeys[openKeys.length - 1]) {
                  setOpenKey(openKeys[openKeys.length - 1]);
                }
              }}
            />
          </>
          }
        </div>
        <div className='h-16 p-2 border-0 border-t border-gray-300 border-solid'>
          {/* {(user.activeSubscription?.status !== 'active' && user.activeSubscription?.status !== 'trialing') && <div className="py-4 text-center">
            <Button
              size='large'
              onClick={setOpenModal}
              className="w-4/5 font-semibold"
            >{user.pm_last_four ? 'Upgrade to PRO' : '7 Day Free Trial'}</Button>
          </div>} */}
          <UserMenu />
        </div>
      </div>
      <StepModal open={openTour} setOpen={setOpenTour} />
      <Modal centered classNames={{ content: "!bg-white" }} width={500} open={showAnalyzeModal} onOk={() => { }} onCancel={() => { }} footer={[]} closable={false} maskClosable={false}>
        <div className='text-center text-black'>
          <Progress strokeLinecap="butt" percent={40} showInfo={false} size={["100%", 12]} strokeColor="#1b0035" trailColor="#e2d0a1" />
          <h1 className='my-4 font-extrabold text-center text-primary'>Your First Analysis!</h1>
          <p className='p-3 my-4 text-[16px] text-gray-100 rounded-md bg-primary'><b>Abby:</b> I did a quick analysis on our conversation so far...</p>
          <div className='my-2 md:my-4'>
            <Button className={classNames('m-1 rounded-none', selectedTab != 'positive' && 'bg-[#eee]')} size='' onClick={() => setSelectedTab("positive")}>Positive Traits</Button>
            <Button className={classNames('m-1 rounded-none', selectedTab != 'areas_for_improvement' && 'bg-[#eee]')} size='' onClick={() => setSelectedTab("areas_for_improvement")}>Areas of Improvement</Button>
          </div>
          <div className="my-2 text-left md:my-4">
            {
              analysisData && analysisData[selectedTab].map((data, index) => <div key={index} className='flex items-center'>
                <GoCheckCircleFill className='w-14' fontSize={30} color={selectedTab == 'positive' ? '#27cf27' : '#DB9516'} />
                <p className='flex-1 my-2 md:my-4' dangerouslySetInnerHTML={{ __html: data.replace("**", '<b>').replace("**", '</b>') }}></p>
              </div>)
            }
          </div>
          <Button className='px-20 font-bold' type='primary' size='large' onClick={() => {
            setShowAnalyzeModal(false);
          }}>Keep Chatting</Button>
        </div>
      </Modal>
      <LimitModal open={showExpiredModal} onClose={() => dispatch(setShowExpiredModal(false))} thread={thread} />
      <WelcomebackModal open={showWelcomebackModal} onClose={() => {
        handleNewChat();
      }} /* to="/playground" */ />
      <SafeModal open={showSafeModal} onClose={() => {
        setShowSafeModal(false);
        // setOpenTour(true);
        dispatch(getThreads());
        // setShowPurposeModal(true);
      }} />
      <PurposeModal open={showPurposeModal} onClose={(goal) => {
        setTitling(true);
        updateThread(id, { goal }).then((res) => {
          dispatch(setGenerating(true));
          dispatch(setMessages([
            {
              role: 'assistant',
              content: "",
            },
            {
              role: 'user',
              content: goal.trim(),
            },
          ]));
          socket.emit('sendMessage', { prompt: goal.trim(), stream: true, threadId: selectedThread, text: true });
          dispatch(getThreads());
        }).catch((err) => console.log(err)).finally(() => {
          setTitling(false);
        });
        setStorage('registered');
        setShowPurposeModal(false);
      }} />
    </Sider>
  )
}

export default SideBar;