
const constants = {
  dev: {
    HOST_URL: 'http://localhost:5000/api/',
    stripePK: 'pk_test_51MqDHeIhTKltFnGq3WGCHjZi5ZfUg5T8aiGVPa7XFZcrse4SGLvTjDbXDNb5inKjlOaJqHJRDv5HsPQ6tbnfsxRL00rwetwKzu',
    SOCKET_URL: 'http://localhost:5000',
    FREE_CREDIT: 20,
  },
  prod: {
    HOST_URL: process.env.REACT_APP_HOST_URL || '/api/',
    stripePK: 'pk_live_51PLuKzKiF66IIHL1z9Q5X6zKVFgVhIdmyxtT4h21CFNsMtnj4Iedhx2xDoD0S4t55zM3x3vRmz21DguTK4IxhmRm00gx3PWxSg',
    // stripePK: 'pk_test_51MqDHeIhTKltFnGq3WGCHjZi5ZfUg5T8aiGVPa7XFZcrse4SGLvTjDbXDNb5inKjlOaJqHJRDv5HsPQ6tbnfsxRL00rwetwKzu',
    SOCKET_URL: '',
    FREE_CREDIT: 20,
  }
};

const REACT_APP_ENV = process.env.REACT_APP_ENV || "dev";

export default constants[REACT_APP_ENV];
